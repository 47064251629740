<template>
  <div>
    <h4 class="text-center text-h4 mb-3">Prioridad</h4>

    <p class="text-center">
      Total de pacientes:
      <v-chip
        label
        color="blue lighten-4"
        class="font-work-sans font-weight-bold primary--text"
        >{{ patients.length }}
      </v-chip>
    </p>

    <v-data-table
      :headers="headers"
      :items="patients"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1 rounded-lg"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.time="{ item }">
        {{ formatHours(item.createdAt) }}
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="pageVisible"
        color="red"
        class="font-work-sans"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory'
const QueueRepository = Repository.get('queues')

export default {
  name: 'Priority',
  data: () => ({
    patients: [],
    itemsPerPage: 7,
    pageVisible: 5,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Número',
        align: 'center',
        class:
          'primary white--text rounded-tl-lg font-weight-bold text-subtitle-2',
        value: 'number',
      },
      {
        text: 'Tiempo',
        align: 'center',
        class:
          'primary white--text rounded-tr-lg font-weight-bold text-subtitle-2',
        value: 'time',
      },
    ],
  }),
  sockets: {
    'attend-patient': function ({ id, cashDeskNumber }) {
      const index = this.patients.findIndex((item) => item.id === id)
      if (index > -1) {
        const [patient] = this.patients.splice(index, 1)
        // patient.cashDeskId = cashDeskNumber
        this.$eventBus.emit('attend-patient', { ...patient, cashDeskNumber })
      }
    },

    'assign-priority': function ({ priorityId, ...item }) {
      if ([2, 3].includes(priorityId)) {
        this.patients.push({ ...item, priorityId })
      } else if (priorityId === 1) {
        const findLastIndex = this.patients.filter(
          (ticket) => ticket.priorityId === 1
        )
        this.patients.splice(findLastIndex.length, 0, { ...item, priorityId })
      }
    },

    'reassign-priority': function ({ id }) {
      const index = this.patients.findIndex((item) => item.id === id)
      if (index > -1) {
        const [patient] = this.patients.splice(index, 1)
        this.patients.push(patient)
      }
    },

    'delete-in-queue': function ({ id }) {
      const index = this.patients.findIndex((item) => item.id === id)
      if (index > -1) {
        const [patient] = this.patients.splice(index, 1)
      }
    },
  },
  mounted: async function () {
    await this.loadData()

    this.$eventBus.on('assign-priority', (data) => {
      this.patients.push(data)
    })
  },
  methods: {
    loadData: async function () {
      try {
        const res = (await QueueRepository.inQueue()).data

        this.patients = res.data.items.priorityQueue
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error

        console.log(response)
      }
    },
    formatHours: (value) => {
      const date = value ? new Date(value) : new Date()
      return date.toLocaleString('es-VE', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    },
  },
}
</script>
