<template>
  <v-row justify="center">
    <v-col cols="12" md="8">
      <v-card class="my-10 px-5 bg-card rounded-xl">
        <h4 class="text-center text-h4 my-5">Pacientes atendidos</h4>
        <v-row>
          <v-col cols="12" class="mb-5">
            <v-row justify="center">
              <v-col cols="12" md="">
                <shifts />
              </v-col>
              <v-divider
                vertical
                class="my-8 mx-lg-6 d-md-block d-none"
              ></v-divider>
              <v-col cols="12" md="">
                <sampling />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Shifts from './Shifts.vue'
import Sampling from './Sampling.vue'
export default {
  components: { Sampling, Shifts },
  name: 'Attended',
}
</script>
