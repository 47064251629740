<template>
  <v-card class="bg-card rounded-xl py-5 px-md-0">
    <h4 class="text-center text-h4 mb-5">Cajas</h4>

    <v-data-table
      :headers="headers"
      :items="cashDesk"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1 rounded-lg mx-5"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.state="{ item }">
        <v-chip
          label
          :color="`${getColorState(item.isLogged)} lighten-4`"
          :class="`font-work-sans font-weight-bold ${getColorState(
            item.isLogged
          )}--text text-uppercase`"
          >{{ getState(item.isLogged) }}
        </v-chip>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="pageVisible"
        color="red"
        class="font-work-sans"
      ></v-pagination>
    </div>
  </v-card>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory'
const CashDeskRepository = Repository.get('cashDesks')

export default {
  name: 'CashDeskHome',
  data: () => ({
    cashDesk: [],
    itemsPerPage: 6,
    pageVisible: 5,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Número',
        align: 'center',
        class:
          'primary white--text rounded-tl-lg font-weight-bold text-subtitle-2',
        value: 'number',
      },
      {
        text: 'Descripción',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'description',
      },
      {
        text: 'Estado',
        align: 'center',
        class:
          'primary white--text rounded-tr-lg font-weight-bold text-subtitle-2',
        value: 'state',
      },
    ],
  }),
  sockets: {
    'connect-cashDesk': function ({ id }) {
      const index = this.cashDesk.findIndex((item) => item.id === id)
      if (index > -1) {
        this.cashDesk[index].isLogged = true
      }
    },

    'disconnect-cashDesk': function ({ id }) {
      const index = this.cashDesk.findIndex((item) => item.id === id)
      if (index > -1) {
        this.cashDesk[index].isLogged = false
      }
    },
  },
  mounted: async function () {
    await this.loadData()
  },
  methods: {
    loadData: async function () {
      this.$eventBus.emit('loading-page')
      try {
        const res = (await CashDeskRepository.getAll()).data
        this.cashDesk = res.data
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error

        console.log(response)
      }
    },
    getColorState: (state) => (state ? 'green' : 'red'),
    getState: (state) => (state ? 'Activo' : 'Inactivo'),
  },
}
</script>
