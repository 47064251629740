<template>
  <v-container>
    <unattended />

    <attended />

    <v-divider class="mb-8 mx-lg-6 d-md-block d-none"></v-divider>

    <devices />
  </v-container>
</template>

<script>
import Attended from './Attended/Attended.vue'
import Devices from './Devices/Devices.vue'
import Unattended from './Unattended/Unattended.vue'

export default {
  components: { Unattended, Attended, Devices },
  name: 'Index',
}
</script>
