<template>
  <v-row class="mb-5" justify="center">
    <v-col cols="12" md="6" class="pr-md-15">
      <monitors />
    </v-col>

    <v-col cols="12" md="6" class="pl-md-15">
      <cash-desk />
    </v-col>

    <v-col cols="12" md="6" class="pr-md-15">
      <sampling-station />
    </v-col>
  </v-row>
</template>

<script>
import SamplingStation from './SamplingStation.vue'
import CashDesk from './CashDesk.vue'
import Monitors from './Monitors.vue'
export default {
  components: { Monitors, CashDesk, SamplingStation },
  name: 'DevicesHome',
}
</script>
