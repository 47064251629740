<template>
  <div>
    <h4 class="text-center text-h4 primary--text mb-3">Turnos</h4>

    <p class="text-center">
      Total de pacientes:
      <v-chip
        label
        color="green lighten-4"
        class="font-work-sans font-weight-bold success--text"
        >{{ patients.items.length }}
      </v-chip>
    </p>

    <!-- <p class="text-center">
      Tiempo promedio:
      <v-chip
        label
        color="green lighten-4"
        class="font-work-sans font-weight-bold success--text text-uppercase"
        >{{ time }}
      </v-chip>
    </p> -->

    <v-data-table
      :headers="headers"
      :items="patients.items"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1 rounded-lg"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.cashDeskId="{ item }">
        {{ `Caja ${item.cashDeskNumber}` }}
      </template>
      <template v-slot:item.time="{ item }">
        {{ formatHours(item.attentionDate) }}
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="pageVisible"
        color="red"
        class="font-work-sans"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory'
const QueueRepository = Repository.get('queues')

export default {
  name: 'Particular',
  data: () => ({
    patients: { items: [] },
    time: '1H 45MIN',
    itemsPerPage: 7,
    pageVisible: 5,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: 'Número',
        align: 'center',
        class:
          'primary white--text rounded-tl-lg font-weight-bold text-subtitle-2',
        value: 'number',
      },
      {
        text: 'Tiempo',
        align: 'center',
        class: 'primary white--text font-weight-bold text-subtitle-2',
        value: 'time',
      },
      {
        text: 'Taquilla',
        align: 'center',
        class:
          'primary white--text rounded-tr-lg font-weight-bold text-subtitle-2',
        value: 'cashDeskId',
      },
    ],
  }),
  sockets: {
    'assign-samping': function ({ id }) {
      console.log('shifts sampling', id)
      const index = this.patients.items.findIndex((item) => item.id === id)
      console.log('assign - samping', index)
      if (index > -1) {
        const patient = this.patients.items.splice(index, 1)
        this.$eventBus.emit('assign-samping', patient[0])
      }
    },

    'undo-to-sampling': function ({ id }) {
      const index = this.patients.items.findIndex((item) => item.id === id)
      if (index > -1) {
        const patient = this.patients.items.splice(index, 1)
        this.$eventBus.emit('assign-samping', patient[0])
      }
    },

    'undo-attended': function ({ id }) {
      const index = this.patients.items.findIndex((item) => item.id === id)
      if (index > -1) {
        const [patient] = this.patients.items.splice(index, 1)
        this.$eventBus.emit('assign-priority', patient)
      }
    },
  },
  mounted: async function () {
    await this.loadData()
    this.$eventBus.on('attend-patient', (data) => {
      this.patients.items.splice(0, 0, data)
    })
  },
  methods: {
    loadData: async function () {
      try {
        const res = (await QueueRepository.attended()).data

        this.patients = res.data
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error

        console.log(response)
      }
    },

    formatHours: (value) => {
      const date = value ? new Date(value) : new Date()
      return date.toLocaleString('es-VE', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    },
  },
}
</script>
