<template>
  <v-card class="bg-card rounded-xl px-5 px-md-0">
    <h4 class="text-center text-h4 my-5">Pacientes por atender</h4>
    <v-row justify="center">
      <v-col cols="12" md="7" class="mb-5">
        <h4 class="text-center text-h4 mb-5 primary--text">Turnos</h4>
        <v-row>
          <v-col cols="12" md="6">
            <particular />
          </v-col>
          <v-col cols="12" md="6">
            <priority />
          </v-col>
        </v-row>
      </v-col>

      <v-divider vertical class="my-8 mx-lg-6 d-md-block d-none"></v-divider>

      <v-col cols="12" md="4" class="mb-5 text-center">
        <h4 class="text-center text-h4 mb-5 secondary--text">Muestras</h4>

        <sampling />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Particular from './Particular.vue'
import Priority from './Priority.vue'
import Sampling from './Sampling.vue'
export default {
  components: { Particular, Priority, Sampling },
  name: 'Unattended',
}
</script>
