<template>
  <div>
    <index />
  </div>
</template>

<script>
import Index from '@/components/Index/Index.vue'
export default {
  components: { Index },
  name: 'Home',
}
</script>
